/********** Template CSS **********/
:root {
    --primary: #3e66b0;
    /* --primary: #16D5FF; */
    --secondary: #999999;
    --light: #F2F8FE;
    --dark: #111111;
}

body {
    background-color: #F2F8FE
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 60px;
    z-index: 99;
}

.whiteColor {
    background-color: #fff
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 0.7;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary {
    color: #FFFFFF;
    border-radius: 30px;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-brand {
    height: 75px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: var(--dark);
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}

.navbar .btn:hover {
    color: #FFFFFF !important;
    background: var(--primary) !important;
}


/*** Header ***/
.hero-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--secondary);
}


/*** Service ***/
.service-item {
    position: relative;
    overflow: hidden;
}

.service-item::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -80px;
    right: -80px;
    border: 80px solid;
    transform: rotate(45deg);
    opacity: 1;
    transition: .5s;
}

.service-item.bg-white::after {
    border-color: transparent transparent var(--light) transparent;
}

.service-item.bg-light::after {
    border-color: transparent transparent #FFFFFF transparent;
}

.service-item:hover::after {
    opacity: 0;
}


/*** Roadmap ***/
.roadmap-carousel {
    position: relative;
}

.roadmap-carousel::before {
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    top: 20px;
    left: 0;
    border-top: 2px dashed var(--secondary);
}

.roadmap-carousel .roadmap-item .roadmap-point {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 6px;
    left: 50%;
    margin-left: -15px;
    background: #FFFFFF;
    border: 2px solid var(--primary);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.roadmap-carousel .roadmap-item .roadmap-point span {
    display: block;
    width: 18px;
    height: 18px;
    background: var(--primary);
}

.roadmap-carousel .roadmap-item {
    position: relative;
    padding-top: 150px;
    text-align: center;
}

.roadmap-carousel .roadmap-item::before {
    position: absolute;
    content: "";
    width: 0;
    height: 115px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-right: 2px dashed var(--secondary);
}

.roadmap-carousel .roadmap-item::after {
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    top: 111px;
    left: 50%;
    margin-left: -7px;
    transform: rotate(45deg);
    background: var(--primary);
}

.roadmap-carousel .owl-nav {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.roadmap-carousel .owl-nav .owl-prev,
.roadmap-carousel .owl-nav .owl-next {
    margin: 0 12px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 22px;
    color: var(--light);
    background: var(--primary);
    transition: .5s;
}

.roadmap-carousel .owl-nav .owl-prev:hover,
.roadmap-carousel .owl-nav .owl-next:hover {
    color: var(--primary);
    background: var(--dark);
}


/*** FAQs ***/
.accordion .accordion-item {
    border: none;
    margin-bottom: 15px;
}

.accordion .accordion-button {
    background: var(--light);
    border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
    color: #FFFFFF;
    background: var(--primary);
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
    padding: 15px 0 0 0;
}


/*** Footer ***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--secondary);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--secondary);
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .btn.btn-square {
    color: var(--primary);
    border: 1px solid var(--primary);
}

.footer .btn.btn-square:hover {
    color: #FFFFFF;
    background: var(--primary);
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(17, 17, 17, .1);
}

.footer .copyright a {
    color: var(--primary);
}

.footer .copyright a:hover {
    color: var(--dark);
}

/* ----------------------------------------------------------------------------- */
.custom-prev-arrow,
.custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.custom-prev-arrow {
    left: 10px;
    /* Adjust the position as needed */
}

.custom-next-arrow {
    right: 10px;
    /* Adjust the position as needed */
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #11111179;
    /* Adjust the size and color of the arrow */
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #11111179;
    /* Adjust the size and color of the arrow */
}


/* --------------------------------------------------- */
a:hover {
    cursor: pointer;
}

.promotion {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: red;
    /* Reddish color */
    color: white;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.section-heading {
    margin: 40px 0;
    /* Add some spacing above and below the section heading */
}

.section-heading h2 {
    letter-spacing: 2px;
    margin-bottom: 20px;
    position: relative;
}

.section-heading h2::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: #000000;
    margin: 10px auto 0;
}

/* ********************************** partners ***************************************** */

/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 5px;
    left: -40px;
    /* background: var(--primary); */
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 120px);
    height: 2px;
    bottom: 6px;
    left: -60px;
    /* background: var(--primary); */
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
}


.testimonial-img {
    position: relative;
    min-height: 400px;
}

.testimonial-img::after {
    position: absolute;
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 200px;
    color: #EEEEEE;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* z-index: -1; */
}

.testimonial-img img {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    z-index: 1;
}

.testimonial-img img:nth-child(1) {
    top: 0;
    left: 0;
}

.testimonial-img img:nth-child(2) {
    top: 60%;
    left: 20%;
}

.testimonial-img img:nth-child(3) {
    top: 20%;
    left: 60%;
}

.testimonial-img img:nth-child(4) {
    bottom: 0;
    right: 0;
}

.testimonial-img img .animated.pulse {
    animation-duration: 2s;
}

.testimonial-carousel .owl-item img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.testimonial-carousel .owl-nav {
    margin-top: 30px;
    display: flex;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    border-radius: 45px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .2);
    font-size: 22px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: var(--secondary);
    color: var(--dark);
}



.owl-carousel {
    display: block;
    width: 100%;
    z-index: 1;
}

.testimonial-carousel img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.testimonial-carousel p {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-block-start: 1em;
}

.iconArrow {
    font-style: italic;
    color: #50ba61
}

.arrowCont {
    transition: background-color 0.5s;
}

.arrowCont:hover {
    background-color: #3e66b0;
}

.arrowCont:hover .iconArrow {
    color: white;
    transition: color 0.5s;
}

/* Keyframes for slide-down animation */
@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        max-height: 500px;
        /* Adjust this value based on your content height */
        opacity: 1;
    }
}


/* Show class to apply the slide-down animation */
.collapse.navbar-collapse.show {
    animation: slideDown 0.5s forwards;
}


/* *************************************** email address ****************************** */

.email-address {
    cursor: pointer;
}

.email-address .full-email {
    display: none;
}

.email-address:hover .full-email {
    display: inline;
    font-size: 12px;
}

.email-address:hover .short-email {
    display: none;
}

.phone:hover {
    display: inline;
    font-size: 12px;
    cursor: pointer;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1021;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    /* margin-bottom: 10px; */
}

.filter-icon {
    cursor: pointer;
    width: 50px;
    /* Adjust as needed */
    height: 50px;
    /* Adjust as needed */
    /* margin-bottom: 10px; */
    z-index: 1021;
    position: fixed;
    margin-left: 10px;
}

.filter-icon {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.filter-icon:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
    /* Optional: larger shadow on hover */
}

.pyp-5 {
    padding-top: 1.75rem !important;
    padding-bottom: 1rem !important;
}

.inputField {
    border-radius: 10px;
}

/* ********************************* */
.modal-dialog {
    max-width: 700px;
}


.modal-body {
    display: flex;
    align-items: center;
    padding: 20px;
}

.modal-body img {
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
}

.modal-body .product-details {
    flex-grow: 1;
}

.modal-body p {
    margin: 0;
}

.modal-body .product-title {
    font-weight: bold;
    margin-top: 10px;
}


@media (max-width: 576px) {
    .modal-dialog {
        max-width: 100%;
        margin: 1rem;
    }

    .modal-body {
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-body img {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100px;
    }

}

/* ***************** */


.modal-header .close:hover {
    background-color: #50ba61;
    color: #ffffff;
}

.modal-header {
    position: relative;
    background-color: #f8f9fa;
    border-bottom: none;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: #50ba61;
    text-align: center;
}

.modal-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    margin: 0.5rem auto 0;
}

.modal-header .close {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    padding: 0;
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    display: flex;
}


.modal-header .close:focus {
    outline: none;
    box-shadow: none;
}

.img-fluidPopUp {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}


@media (min-width: 448px) and (max-width: 767px) {
    .modal-dialog {
        max-width: 600px;
    }

}

@media (max-width: 560px) {
    .modal-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .modal-title {
        flex: 1 1 auto;
    }

    .close {
        flex: 0 0 auto;
        align-self: flex-start;
    }

    .spaceNextToButton {
        margin-right: 7%;
    }
}

.d-lg-inline-flex {
    display: inline-flex !important;
}

@media (max-width: 992px) {
.iconDiv {
    margin-bottom: 20px;
}
}

/* ErrorMessage.css */
.error-message.show {
	right: 10px;
}

.message-content {
	flex-grow: 1;
	margin-right: 10px;
	margin-left: 10px;
}

.error-message {
	position: fixed;
	top: 50%;
	right: -300px;
	transform: translate(0, -50%);
	background-color: #ffffff;
	color: #ff0000;
	padding: 25px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 300px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	transition: right 0.3s ease-in-out;
	z-index: 999999;
}

.close-button {
	cursor: pointer;
	font-size: 18px;
	border-radius: 50%;
	background-color: #ff0000;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -11px;
	left: 0px;
	width: 40px;
	height: 40px;
	transform: scale(1);
	transition: transform 0.3s ease-in-out;

}

.close-button:hover {
	transform: scale(1.2);
}

/* SuccessMessage.css */
.success-message.showSuccess {
	right: 10px;
}

.message-contentSuccess {
	flex-grow: 1;
	margin-right: 10px;
	margin-left: 10px;
}

.success-message {
	position: fixed;
	top: 50%;
	right: -300px;
	transform: translate(0, -50%);
	background-color: #ffffff;
	color: #0b5707;
	padding: 25px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 300px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	transition: right 0.3s ease-in-out;
	z-index: 999999;
}

.close-buttonSuccess {
	cursor: pointer;
	font-size: 18px;
	border-radius: 50%;
	background-color: #0b5707;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -11px;
	left: 0px;
	width: 40px;
	height: 40px;
	transform: scale(1);
	transition: transform 0.3s ease-in-out;

}

.close-buttonSuccess:hover {
	transform: scale(1.2);
}
