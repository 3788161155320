
.section-title h4:after {
	position: absolute;
	left: 0;
	bottom: -4px;
	height: 2px;
	width: 70px;
	background: #3e66b0;
	content: "";
}

/* .spad {

	margin-left: 5%;
} */

/*---------------------
  Shop
-----------------------*/

.shop {
	padding-top: 70px;
	padding-bottom: 10px;
	position: fixed; 
    top: 0; 
    left: 0;

    height: 100%; 
	background-color: rgb(255, 255, 255); 
    z-index: 1022; 
    display: flex; 
	overflow-y: auto;
}



.sidebar__categories {
	margin-bottom: 50px;
}

.sidebar__categories .section-title {
	margin-bottom: 35px;
}

.sidebar__categories .section-title h4 {
	font-size: 18px;
}

.categories__accordion .card {
	border: none;
	border-radius: 0;
	padding-bottom: 12px;
	border-bottom: 1px solid #f2f2f2 !important;
	margin-bottom: 12px;
}

.categories__accordion .card:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none !important;
}

.categories__accordion .card-heading {
	cursor: pointer;
}

.categories__accordion .card-heading a {
	font-size: 14px;
	font-weight: 500;
	color: #111111;
	display: block;
}

.categories__accordion .card-body {
	padding-top: 6px;
	padding-bottom: 0;
}

.categories__accordion .card-body li {
	list-style: none;
	padding-left: 16px;
}

.categories__accordion .card-body li:before {
	position: absolute;
	left: 4px;
	top: 14px;
	height: 1px;
	width: 4px;
	background: #666666;
	content: "";
}

.categories__accordion .card-body li a {
	font-size: 14px;
	color: #666666;
}

.categories__accordion .card-heading a:after,
.categories__accordion .card-heading>a.active[aria-expanded=false]:after {
	
	position: absolute;
	right: 30px;
	top: 0px;
	
	border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
	font-size: 14px;
}

.categories__accordion .card-heading.active a:after {
	border: none;
    content: "\f106";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
	font-size: 14px;
}

.categories__accordion .card-heading a[aria-expanded=true]:after,
.categories__accordion .card-heading>a.active:after {
	content: "";
	font-size: 14px;
	font-family: "FontAwesome";
	color: #666666;
	position: absolute;
	right: 30px;
	top: -1px;
}

.sidebar__filter {
	position: relative;
	margin-bottom: 60px;
}

.sidebar__filter .section-title {
	margin-bottom: 50px;
}

.sidebar__filter .section-title h4 {
	font-size: 18px;
}

.sidebar__filter a {
	font-size: 14px;
	color: #0d0d0d;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 700;
	display: inline-block;
	padding: 5px 16px 5px 24px;
	border: 2px solid #ff0000;
	position: absolute;
	right: 0;
	bottom: -5px;
	border-radius: 2px;
}

.filter-range-wrap .range-slider .price-input {
	position: relative;
}

.filter-range-wrap .range-slider .price-input p {
	font-size: 16px;
	color: #0d0d0d;
	font-weight: 500;
	display: inline-block;
	margin-bottom: 0;
}

.filter-range-wrap .range-slider .price-input:after {
	position: absolute;
	left: 81px;
	top: 12px;
	height: 1px;
	width: 5px;
	background: #0d0d0d;
	content: "";
}

.filter-range-wrap .range-slider .price-input input {
	font-size: 16px;
	color: #0d0d0d;
	max-width: 16%;
	border: none;
}

.filter-range-wrap .range-slider .price-input input:nth-child(1) {
	margin-right: 28px;
}

.filter-range-wrap .price-range {
	border-radius: 0;
	margin-bottom: 28px;
}

.filter-range-wrap .price-range.ui-widget-content {
	border: none;
	background: rgba(0, 0, 0, 0.1);
	height: 5px;
}

.filter-range-wrap .price-range.ui-widget-content .ui-slider-handle {
	height: 14px;
	width: 14px;
	border-radius: 50%;
	background: #ca1515;
	border: none;
	outline: none;
	cursor: pointer;
}

.filter-range-wrap .price-range .ui-slider-range {
	background: #ca1515;
	border-radius: 0;
}

.sidebar__sizes,
.sidebar__color {
	margin-bottom: 40px;
}

.sidebar__sizes .section-title,
.sidebar__color .section-title {
	margin-bottom: 35px;
}

.sidebar__sizes .section-title h4,
.sidebar__color .section-title h4 {
	font-size: 18px;
}

.sidebar__sizes .size__list label,
.sidebar__color .size__list label {
	display: block;
	padding-left: 20px;
	font-size: 14px;
	text-transform: uppercase;
	color: #444444;
	position: relative;
	cursor: pointer;
}

.sidebar__sizes .size__list label input,
.sidebar__color .size__list label input {
	position: absolute;
	visibility: hidden;
}

.sidebar__sizes .size__list label input:checked~.checkmark,
.sidebar__color .size__list label input:checked~.checkmark {
	border-color: #ca1515;
}

.sidebar__sizes .size__list label input:checked~.checkmark:after,
.sidebar__color .size__list label input:checked~.checkmark:after {
	border-color: #ca1515;
	opacity: 1;
}

.sidebar__sizes .size__list label .checkmark,
.sidebar__color .size__list label .checkmark {
	position: absolute;
	left: 0;
	top: 4px;
	height: 10px;
	width: 10px;
	border: 1px solid #444444;
	border-radius: 2px;
}

.sidebar__sizes .size__list label .checkmark:after,
.sidebar__color .size__list label .checkmark:after {
	position: absolute;
	left: 0px;
	top: -2px;
	width: 11px;
	height: 5px;
	border: solid #ffffff;
	border-width: 1.5px 1.5px 0px 0px;
	-webkit-transform: rotate(127deg);
	-ms-transform: rotate(127deg);
	transform: rotate(127deg);
	opacity: 0;
	content: "";
}

.sidebar__color .color__list label {
	text-transform: capitalize;
}

/*---------------------
  Shop Cart
-----------------------*/

.shop-cart {
	padding-top: 70px;
	padding-bottom: 20px;
}

.shop__cart__table {
	margin-bottom: 30px;
}

.shop__cart__table table {
	width: 100%;
}

.shop__cart__table thead {
	border-bottom: 1px solid #f2f2f2;
}

.shop__cart__table thead th {
	font-size: 18px;
	color: #111111;
	font-weight: 600;
	text-transform: uppercase;
	padding-bottom: 20px;
}

.shop__cart__table tbody tr {
	border-bottom: 1px solid #f2f2f2;
}

.shop__cart__table tbody tr td {
	padding: 30px 0;
}

.shop__cart__table tbody tr .cart__product__item {
	overflow: hidden;
	width: 585px;
}

.shop__cart__table tbody tr .cart__product__item img {
	float: left;
	margin-right: 25px;
}

.shop__cart__table tbody tr .cart__product__item .cart__product__item__title {
	overflow: hidden;
	padding-top: 23px;
}

.shop__cart__table tbody tr .cart__product__item .cart__product__item__title h6 {
	color: #111111;
	font-weight: 600;
}

.shop__cart__table tbody tr .cart__product__item .cart__product__item__title .rating i {
	font-size: 10px;
	color: #e3c01c;
	margin-right: -4px;
}

.shop__cart__table tbody tr .cart__price {
	font-size: 16px;
	color: #ca1515;
	font-weight: 600;
	width: 190px;
}

.shop__cart__table tbody tr .cart__quantity {
	width: 190px;
}

.shop__cart__table tbody tr .cart__quantity .pro-qty {
	border: none;
	padding: 0;
	width: 110px;
	border-radius: 0;
}

.shop__cart__table tbody tr .cart__quantity .pro-qty input {
	color: #444444;
}

.shop__cart__table tbody tr .cart__quantity .pro-qty .qtybtn {
	font-size: 16px;
	color: #444444;
}

.shop__cart__table tbody tr .cart__total {
	font-size: 16px;
	color: #ca1515;
	font-weight: 600;
	width: 150px;
}

.shop__cart__table tbody tr .cart__close {
	text-align: right;
}

.shop__cart__table tbody tr .cart__close span {
	height: 45px;
	width: 45px;
	background: #f2f2f2;
	border-radius: 50%;
	font-size: 18px;
	color: #111111;
	line-height: 44px;
	text-align: center;
	display: inline-block;
	font-weight: 600;
	cursor: pointer;
}

.cart__btn {
	margin-bottom: 50px;
}

.cart__btn.update__btn {
	text-align: right;
}

.cart__btn a {
	font-size: 14px;
	color: #111111;
	font-weight: 600;
	text-transform: uppercase;
	display: inline-block;
	padding: 14px 30px 12px;
	background: #f5f5f5;
}

.cart__btn a span {
	color: #ca1515;
	font-size: 14px;
	margin-right: 5px;
}

.discount__content h6 {
	color: #111111;
	font-weight: 600;
	text-transform: uppercase;
	display: inline-block;
	margin-right: 30px;
}

.discount__content form {
	position: relative;
	width: 370px;
	display: inline-block;
}

.discount__content form input {
	height: 52px;
	width: 100%;
	border: 1px solid #444444;
	border-radius: 50px;
	padding-left: 30px;
	padding-right: 115px;
	font-size: 14px;
	color: #444444;
}

.discount__content form input::-webkit-input-placeholder {
	color: #444444;
}

.discount__content form input::-moz-placeholder {
	color: #444444;
}

.discount__content form input:-ms-input-placeholder {
	color: #444444;
}

.discount__content form input::-ms-input-placeholder {
	color: #444444;
}

.discount__content form input::placeholder {
	color: #444444;
}

.discount__content form button {
	position: absolute;
	right: 4px;
	top: 4px;
}

.cart__total__procced {
	background: #f5f5f5;
	padding: 40px;
}

.cart__total__procced h6 {
	color: #111111;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.cart__total__procced ul {
	margin-bottom: 25px;
}

.cart__total__procced ul li {
	list-style: none;
	font-size: 16px;
	color: #111111;
	font-weight: 600;
	overflow: hidden;
	line-height: 40px;
}

.cart__total__procced ul li span {
	color: #ca1515;
	float: right;
}

.cart__total__procced .primary-btn {
	display: block;
	border-radius: 50px;
	text-align: center;
	padding: 12px 0 10px;
}

/*---------------------
  Checkout
-----------------------*/

.checkout {
	padding-top: 80px;
	padding-bottom: 70px;
}

.coupon__link {
	font-size: 14px;
	color: #444444;
	padding: 14px 0;
	background: #f5f5f5;
	border-top: 2px solid #ca1515;
	text-align: center;
	margin-bottom: 50px;
}

.coupon__link a {
	font-size: 14px;
	color: #444444;
}

.coupon__link span {
	font-size: 14px;
	color: #ca1515;
}

.checkout__form h5 {
	color: #111111;
	font-weight: 600;
	text-transform: uppercase;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
	margin-bottom: 25px;
}

.checkout__form .checkout__form__input p {
	color: #444444;
	font-weight: 500;
	margin-bottom: 10px;
}

.checkout__form .checkout__form__input p span {
	color: #ca1515;
}

.checkout__form .checkout__form__input input {
	height: 50px;
	width: 100%;
	border: 1px solid #e1e1e1;
	border-radius: 2px;
	margin-bottom: 25px;
	font-size: 14px;
	padding-left: 20px;
	color: #666666;
}

.checkout__form .checkout__form__input input::-webkit-input-placeholder {
	color: #666666;
}

.checkout__form .checkout__form__input input::-moz-placeholder {
	color: #666666;
}

.checkout__form .checkout__form__input input:-ms-input-placeholder {
	color: #666666;
}

.checkout__form .checkout__form__input input::-ms-input-placeholder {
	color: #666666;
}

.checkout__form .checkout__form__input input::placeholder {
	color: #666666;
}

.checkout__form .checkout__form__checkbox {
	margin-bottom: 20px;
}

.checkout__form .checkout__form__checkbox label {
	display: block;
	padding-left: 24px;
	font-size: 14px;
	color: #444444;
	font-weight: 500;
	position: relative;
	cursor: pointer;
	margin-bottom: 16px;
}

.checkout__form .checkout__form__checkbox label input {
	position: absolute;
	visibility: hidden;
}

.checkout__form .checkout__form__checkbox label input:checked~.checkmark {
	border-color: #ca1515;
}

.checkout__form .checkout__form__checkbox label input:checked~.checkmark:after {
	border-color: #ca1515;
	opacity: 1;
}

.checkout__form .checkout__form__checkbox label .checkmark {
	position: absolute;
	left: 0;
	top: 4px;
	height: 10px;
	width: 10px;
	border: 1px solid #444444;
	border-radius: 2px;
}

.checkout__form .checkout__form__checkbox label .checkmark:after {
	position: absolute;
	left: 0px;
	top: -2px;
	width: 11px;
	height: 5px;
	border: solid #ffffff;
	border-width: 1.5px 1.5px 0px 0px;
	-webkit-transform: rotate(127deg);
	-ms-transform: rotate(127deg);
	transform: rotate(127deg);
	opacity: 0;
	content: "";
}

.checkout__form .checkout__form__checkbox p {
	margin-bottom: 0;
}

.checkout__order {
	background: #f5f5f5;
	padding: 30px;
}

.checkout__order h5 {
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 18px;
}

.checkout__order .site-btn {
	width: 100%;
}

.checkout__order__product {
	border-bottom: 1px solid #d7d7d7;
	padding-bottom: 22px;
}

.checkout__order__product ul li {
	list-style: none;
	font-size: 14px;
	color: #444444;
	font-weight: 500;
	overflow: hidden;
	margin-bottom: 14px;
	line-height: 24px;
}

.checkout__order__product ul li:last-child {
	margin-bottom: 0;
}

.checkout__order__product ul li span {
	font-size: 14px;
	color: #111111;
	font-weight: 600;
	float: right;
}

.checkout__order__product ul li .top__text {
	font-size: 16px;
	color: #111111;
	font-weight: 600;
	float: left;
}

.checkout__order__product ul li .top__text__right {
	font-size: 16px;
	color: #111111;
	font-weight: 600;
	float: right;
}

.checkout__order__total {
	padding-top: 12px;
	border-bottom: 1px solid #d7d7d7;
	padding-bottom: 10px;
	margin-bottom: 25px;
}

.checkout__order__total ul li {
	list-style: none;
	font-size: 16px;
	color: #111111;
	font-weight: 600;
	overflow: hidden;
	line-height: 40px;
}

.checkout__order__total ul li span {
	color: #ca1515;
	float: right;
}

.checkout__order__widget {
	padding-bottom: 10px;
}

.checkout__order__widget label {
	display: block;
	padding-left: 25px;
	font-size: 14px;
	font-weight: 500;
	color: #111111;
	position: relative;
	cursor: pointer;
	margin-bottom: 14px;
}

.checkout__order__widget label input {
	position: absolute;
	visibility: hidden;
}

.checkout__order__widget label input:checked~.checkmark {
	border-color: #ca1515;
}

.checkout__order__widget label input:checked~.checkmark:after {
	border-color: #ca1515;
	opacity: 1;
}

.checkout__order__widget label .checkmark {
	position: absolute;
	left: 0;
	top: 4px;
	height: 10px;
	width: 10px;
	border: 1px solid #444444;
	border-radius: 2px;
}

.checkout__order__widget label .checkmark:after {
	position: absolute;
	left: 0px;
	top: -2px;
	width: 11px;
	height: 5px;
	border: solid #ffffff;
	border-width: 1.5px 1.5px 0px 0px;
	-webkit-transform: rotate(127deg);
	-ms-transform: rotate(127deg);
	transform: rotate(127deg);
	opacity: 0;
	content: "";
}



/*---------------------
  Contact
-----------------------*/

.contact {
	padding-top: 80px;
	padding-bottom: 80px;
}

.contact__address {
	margin-bottom: 45px;
}

.contact__address h5 {
	color: #111111;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.contact__address ul li {
	list-style: none;
	position: relative;
	margin-bottom: 20px;
}

.contact__address ul li:last-child {
	margin-bottom: 0;
}

.contact__address ul li h6 {
	color: #111111;
	font-weight: 600;
	margin-bottom: 10px;
}

.contact__address ul li h6 i {
	font-size: 16px;
	color: #ca1515;
	margin-right: 5px;
}

.contact__address ul li p {
	margin-bottom: 0;
	font-size: 15px;
	color: #444444;
}

.contact__address ul li span {
	font-size: 15px;
	color: #444444;
	display: inline-block;
	margin-right: 25px;
	position: relative;
}

.contact__address ul li span:after {
	position: absolute;
	right: -15px;
	top: 11px;
	content: "|";
	line-height: 0;
}

.contact__address ul li span:last-child {
	margin-right: 0;
}

.contact__address ul li span:last-child:after {
	display: none;
}

.contact__form h5 {
	color: #111111;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 35px;
}

.contact__form form input {
	height: 50px;
	width: 100%;
	padding-left: 20px;
	font-size: 14px;
	color: #444444;
	border: 1px solid #e1e1e1;
	border-radius: 10px;
	margin-bottom: 20px;
}

.contact__form form input::-webkit-input-placeholder {
	color: #444444;
}

.contact__form form input::-moz-placeholder {
	color: #444444;
}

.contact__form form input:-ms-input-placeholder {
	color: #444444;
}

.contact__form form input::-ms-input-placeholder {
	color: #444444;
}

.contact__form form input::placeholder {
	color: #444444;
}

.contact__form form textarea {
	height: 130px;
	width: 100%;
	padding-left: 20px;
	padding-top: 12px;
	font-size: 14px;
	color: #444444;
	border: 1px solid #e1e1e1;
	border-radius: 10px;
	margin-bottom: 14px;
	resize: none;
}

.contact__form form textarea::-webkit-input-placeholder {
	color: #444444;
}

.contact__form form textarea::-moz-placeholder {
	color: #444444;
}

.contact__form form textarea:-ms-input-placeholder {
	color: #444444;
}

.contact__form form textarea::-ms-input-placeholder {
	color: #444444;
}

.contact__form form textarea::placeholder {
	color: #444444;
}

.contact__map {
	height: 780px;
}

.contact__map iframe {
	width: 100%;
}

/*--------------------------------- Responsive Media Quaries -----------------------------*/




/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	
	.sidebar__filter a {
		position: relative;
		bottom: 0;
	}
	.filter-range-wrap .range-slider .price-input input {
		max-width: 27%;
	}
	.filter-range-wrap .range-slider .price-input {
		margin-bottom: 20px;
	}
	
	.checkout__order {
		margin-top: 20px;
	}
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
	
	.filter__controls {
		text-align: left;
		margin-bottom: 40px;
	}
	.filter__controls li {
		margin-right: 20px;
	}
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
	
	
	.filter__controls li {
		margin-right: 5px;
		margin-bottom: 5px;
	}
}

.card {
	background-color: transparent;
}

/* ****************** */
.sbHide {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.sbShow {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8; /* Adjust to your desired final opacity */
    }
}

.wrapperSb {
    animation: fadeIn 0.3s ease-in-out forwards; /* Adjust duration and easing as needed */
}

.icon_close{
	font-family: 'ElegantIcons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.icon_close:before {
    content: "\00D7";
}

/* *************************************** */
@media only screen and (max-width: 767px) {
	.canvas__open {
		display: block;
		font-size: 22px;
		color: #222;
		height: 35px;
		width: 35px;
		line-height: 35px;
		text-align: center;
		border: 1px solid #323232;
		border-radius: 2px;
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 24px;
	}
	.offcanvas-menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.7);
		content: "";
		z-index: 98;
		-webkit-transition: all, 0.5s;
		-o-transition: all, 0.5s;
		transition: all, 0.5s;
		visibility: hidden;
	}
	.offcanvas-menu-overlay.active {
		visibility: visible;
	}
	.offcanvas-menu-wrapper {
		position: fixed;
		left: -270px;
		width: 270px;
		height: 100%;
		background: #ffffff;
		padding: 90px 20px 30px 30px;
		display: block;
		z-index: 99;
		overflow-y: auto;
		-webkit-transition: all, 0.5s;
		-o-transition: all, 0.5s;
		transition: all, 0.5s;
		opacity: 0;
	}
	.offcanvas-menu-wrapper.active {
		opacity: 1;
		left: 0;
	}
	.offcanvas__close {
		position: absolute;
		width: 40px;
		height: 40px;
		right: 30px;
		top: 25px;
		border: 1px solid #ddd;
		border-radius: 50%;
		font-size: 26px;
		text-align: center;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		cursor: pointer;
	}
	.offcanvas__menu {
		display: none;
	}
	.slicknav_btn {
		display: none;
	}
	.slicknav_menu {
		background: transparent;
		padding: 0;
		margin-bottom: 20px;
	}
	.slicknav_nav ul {
		margin: 0;
	}
	.slicknav_nav .slicknav_row,
	.slicknav_nav a {
		padding: 7px 0;
		margin: 0;
		color: #111111;
		font-weight: 600;
	}
	.slicknav_nav .slicknav_row:hover {
		border-radius: 0;
		background: transparent;
		color: #111111;
	}
	.slicknav_nav a:hover {
		border-radius: 0;
		background: transparent;
		color: #111111;
	}
	.slicknav_nav {
		display: block !important;
	}
	.offcanvas__logo {
		margin-bottom: 25px;
	}
	.offcanvas__widget {
		text-align: center;
		margin-bottom: 20px;
	}
	.offcanvas__widget li {
		list-style: none;
		display: inline-block;
		font-size: 18px;
		color: #111111;
		margin-right: 20px;
		cursor: pointer;
	}
	.offcanvas__widget li:last-child {
		margin-right: 0;
	}
	.offcanvas__widget li a {
		font-size: 18px;
		color: #111111;
		position: relative;
	}
	.offcanvas__widget li a .tip {
		position: absolute;
		right: -12px;
		top: -11px;
		height: 18px;
		width: 18px;
		background: #111111;
		font-size: 10px;
		font-weight: 500;
		color: #ffffff;
		line-height: 18px;
		text-align: center;
		border-radius: 50%;
	}
	.offcanvas__auth a {
		font-size: 15px;
		color: #111111;
		position: relative;
		margin-right: 8px;
		font-weight: 500;
	}
	.offcanvas__auth a:last-child {
		margin-right: 0;
	}
	.offcanvas__auth a:last-child:after {
		display: none;
	}
	.offcanvas__auth a:after {
		position: absolute;
		right: -8px;
		top: -2px;
		content: "/";
		font-size: 13px;
	}
	.header__menu {
		display: none;
	}
	.header__right {
		display: none;
	}
	.filter__controls {
		text-align: left;
		margin-bottom: 40px;
	}
	.filter__controls li {
		margin-right: 20px;
	}
	.discount__pic img {
		height: auto;
	}
	.discount__text {
		padding: 75px 40px 50px;
		height: auto;
	}
	.product__details__pic__left {
		height: auto;
	}
	.product__details__pic {
		margin-bottom: 30px;
	}
	.quantity {
		float: none;
		margin-right: 0;
	}
	.nav::before {
		display: none;
	}
	.nav::after {
		display: none;
	}
	.shop__cart__table {
		overflow-x: auto;
	}
	.shop__cart__table tbody tr .cart__product__item img {
		float: none;
		margin-right: 0;
	}
	.cart__btn {
		text-align: center;
		margin-bottom: 25px;
	}
	.cart__btn.update__btn {
		text-align: center;
	}
	.discount__content h6 {
		display: block;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.discount__content {
		margin-bottom: 35px;
	}
	.blog__sidebar {
		padding-left: 0;
		padding-top: 40px;
	}
	.contact__content {
		margin-bottom: 40px;
	}
}

.empty-cart-message {
    text-align: center;
    margin: 50px 0;
}

.empty-cart-message h4 {
    font-size: 24px;
    color: #555;
    font-weight: bold;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart__btn a { 
    transition: background-color 0.3s ease;
}

.cart__btn a:hover {
    background-color: #e0e0e0;
}

/*  */
#mobile {
    position: fixed;
    border-radius: 0 50% 50% 0;
    box-shadow: 1.4rem .5rem 2rem rgba(3, 27, 78, .21);
    width: 4.5rem;
    height: 4.5rem;
    overflow: hidden;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    top: 35%;
    background-color: #ffffff;
    cursor: pointer;
    z-index: 1021;
}
#mobile i {
    color: #0050D7;
}

@media only screen and (max-width: 500px) {
.phoneWidth{
	width: 100%;
}
}

.collapse.show {
    animation: slideDown 1s forwards;
}

@keyframes slideUp {
    from {
        max-height: 500px;
        opacity: 1;
    }

    to {
        max-height: 0px;
        opacity: 0;
    }
}

.collapse.hidden {
    animation: slideUp 3s forwards;
}



/* #50ba61; */

.search-container {
    position: relative;
    width: 90%;
    max-width: 450px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    margin-left: 0px;
	z-index: 1000
}

.search-input {
    width: 100%;
    padding: 12px 50px 12px 20px;
    border: 2px solid #ddd;
    border-radius: 25px;
    font-size: 14px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1001
}

.search-input:focus {
    border-color: #50ba61;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    outline: none;
}

.search-button {
    position: absolute;
    right: 10px; 
    top: 50%;
    transform: translateY(-50%);
    background-color: #50ba61; 
    border: none;
    color: white; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding: 8px; 
    border-radius: 50%; 
    transition: background-color 0.3s ease, transform 0.3s ease;
	z-index: 1001
}

.search-button i {
    font-size: 15px;
    pointer-events: none;
	z-index: 1001
}

.search-button:hover {
    background-color: #3e66b0;
}

/* DropDown Styling */
.dropdown-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 25px 25px;
    border: 2px solid #ddd;
    border-top: none;
    z-index: 1000;
    top: 50%;
	padding-top: 35px;
}

.dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
	font-weight: 8px;
}
.dropdown-item img {
    width: 52.9px;
    height: 40px;
    object-fit: cover;
    margin-right: 10px; 
    border-radius: 5px;
}
.dropdown-item:hover {
    background-color: #f1f1f1;
}



