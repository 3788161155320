/* OverlayWrapper.css */
.overlay-wrapper {
    position: relative;
  }
  
  .overlay-wrapper.loading::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999; /* Ensure overlay is on top of other content */
  }
  
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Change to fixed for viewport-centered loader */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; /* Ensure loader container is on top of the overlay */
  }
  
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001; /* Ensure loader is on top of other content */
  }
  
  